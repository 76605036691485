import React, {useEffect, useState, useRef} from 'react'
import { RxCross2 } from "react-icons/rx";

export type BannerType = 'info' | 'error' | 'warning' | 'success' | 'dark'

export interface BannerProps {
    isVisible: boolean;
    hideBanner: () => void;

    showDuration? : number | null;
    showCloseButton?: boolean;
    type?: BannerType;

    message: string | JSX.Element;
    className?: string;
    zIndex?:number | null;
    isStatic?: boolean;
    scrollToTop?: boolean;
    scrollToRef?: React.RefObject<HTMLDivElement>;
}


export const Banner = ({
    isVisible,
    hideBanner,
    
    showDuration=3000, // 3 seconds
    showCloseButton=true,
    type='error',

    message='',
    className,
    zIndex=null,
    isStatic=false,
    scrollToTop=true,
    scrollToRef
}:BannerProps) => {

    // when banner is visible, scroll to see banner
    useEffect(() => {
        if (isVisible) {
            // scroll to top
            if (scrollToTop) {
                window.scrollTo(0,0)
            } else if ( scrollToRef &&  scrollToRef.current) {
                // scroll to banner position
                scrollToRef.current.scrollIntoView({ behavior: "smooth"})
            }
        }
    }, [isVisible, scrollToRef])


    // hide banner timeout
    useEffect(() => {
        if (isVisible && showDuration && hideBanner) {
            const timer = setTimeout(() => {
                hideBanner()
            }, 3000);
            // Clear the timer on component unmount or if the state changes before 5 seconds
            return () => clearTimeout(timer)
        }
    },[isVisible, showDuration, hideBanner])


    //color settings
    const bannerColor = 
        type==='info' ? 'bg-blue-50' :
        type==='error' ? 'bg-red-50' :
        type==='warning' ? 'bg-yellow-50' :
        type==='success' ? 'bg-green-100' :
        type==='dark' ? 'bg-gray-50' :
        'bg-red-50'

    const textColor = 
        type==='info' ? 'text-blue-800' :
        type==='error' ? 'text-red-800' :
        type==='warning' ? 'text-yellow-800' :
        type==='success' ? 'text-green-800' :
        type==='dark' ? 'text-gray-800' :
        'text-red-800'



    return (
        <div
            style={{ 
                zIndex: zIndex ? zIndex : 0
            }}
            className={`
                ${isStatic ? 'z-[-1]' : 'sticky top-0'}
                w-full flex flex-row items-center justify-center
                transition-all duration-500 ease-in-out 
                ${isVisible ? 'opacity-100 px-4 py-2  h-fit' : 'opacity-0 h-0'}
                ${bannerColor} ${className}
            `}
        >
            <div 
                role="alert" 
                // ref={ref} 
                className={`
                    px-4 text-sm w-full flex justify-center
                    ${textColor} 
            `}>
                {message}
            </div>
            
            {   showCloseButton ? (
                <div 
                    className="ml-auto right-0 cursor-pointer"
                    onClick={hideBanner}
                >
                    <RxCross2 size={24} className={`${textColor}`}/>
                </div>
            ) : (<></>)
            }
        </div>
    )
}